<script>
  import {
    init,
    register,
    getLocaleFromQueryString,
    getLocaleFromNavigator,
    locale,
  } from "svelte-i18n";
  register("en", () => import("../../public/lang/en.json"));
  register("fr", () => import("../../public/lang/fr.json"));
  register("de", () => import("../../public/lang/de.json"));
  register("it", () => import("../../public/lang/it.json"));
  register("nl", () => import("../../public/lang/nl.json"));

  let localeFromQuery = getLocaleFromQueryString("lang");
  let language;
  function defaultLanguage() {
    let localeFromBrowser = getLocaleFromNavigator();
    let localeFromStorage = localStorage.getItem("enrol-app-locale");
    let localeDefault = "en";

    if (localeFromStorage) {
      return localeFromStorage;
    }

    if (localeFromBrowser) {
      return localeFromBrowser.slice(0, 2);
    }

    return localeDefault;
  }

  switch (localeFromQuery) {
    case "en":
      language = "en";
      break;
    case "fr":
      language = "fr";
      break;
    case "it":
      language = "it";
      break;
    case "nl":
      language = "nl";
      break;
    case "de":
      language = "de";
      break;
    default:
      language = defaultLanguage();
      break;
  }
  changeLocale();

  function changeLocale() {
    locale.set(language);
    localStorage.setItem("enrol-app-locale", language);
  }

  init({
    fallbackLocale: defaultLanguage(),
    initialLocale: language,
  });
</script>
